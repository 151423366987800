<template>
    <Dialog title="" width="1080px" :visible="ChooseCustomerDialogShow" class="ChooseCustomer f-cl-c"
        @closeDialog="closeDialog">
        <div class="f-c-c">
            <div class="f-c search-input">
                <input type="text" placeholder="请输入客户名称" v-model.trim="searchValue" @keyup.enter="search" maxlength="10" />
            </div>
            <el-button class="search-icon" @click="search" icon="el-icon-search"></el-button>
        </div>
        <div v-if="isSearch" class="tips">搜索到 {{ total }} 个与 “{{ searchValue }}” 相关客户</div>
        <div class="enterprise-list f-w-b" v-if="customerList && customerList.length">
            <div class="enterpriseItem f-c" v-for="(item, index) of customerList" :key="index" @click="choose(item)">
                <div class="customerTypeImg">
                    <i :class="item.customerType == 'B' ? 'el-icon-office-building' : 'el-icon-user'"></i>
                </div>
                <div class="f-cl baseInfo">
                    <div class="customerName">客户名称：{{ item.customerName }}</div>
                    <div class="customerType">客户类型：{{ item.customerType == 'B' ? '企业' : '个人' }}</div>
                </div>
            </div>
            <Pagination :total="total" :margin="'10px'" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </div>
        <el-empty v-else :description="boxTips" class="no-list"></el-empty>
    </Dialog>
</template>

<script>
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
import { ChineseCharactersInputMixin } from '@/mixins/ChineseCharactersInputMixin'

export default {
    name: 'ChooseCustomerBox',
    mixins: [PaginationRetention1, ChineseCharactersInputMixin],
    props: {
        ChooseCustomerDialogShow: {//是否显示邀请盒子
            type: Boolean,
            default: false
        }
    },
    watch: {
        ChooseCustomerDialogShow(newName) {
            if (newName) {
                this.searchListEnterpriseCustomer(this.paging, this.pagingSize)
            }
        }
    },
    data() {
        return {
            customerList: [],
            isSearch: false,//是否启动搜索
            boxTips: '快去添加新客户把！'
        }
    },
    methods: {
        getDate() {
            this.searchListEnterpriseCustomer(this.paging, this.pagingSize)
        },
        // 搜索列表
        search() {
            this.searchValue ? this.isSearch = true : this.isSearch = false;
            this.searchListEnterpriseCustomer()
        },
        // 企业客户搜索列表接口
        searchListEnterpriseCustomer(page = this.page, size = this.size) {
            let that = this;
            that.$http.searchListEnterpriseCustomer({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                customerName: that.searchValue,
                page,
                size
            }).then(res => {
                that.boxTips = res.code == 272 ? '您无权查看！' : '快去添加新客户把！'
                that.customerList = res.data.records;
                that.currentPage = res.data.current;//当前页数
                that.total = res.data.total
            })
        },
        closeDialog() {
            this.customerList = []
            this.searchValue = ''
            this.$emit('closeDialog', false)
            this.$removeStorage('fetchDataPage')
            this.$removeStorage('fetchDataSize')
        },
        choose(data) {
            this.$emit('selectedCustomer', data)
        },
    },
}

</script>
<style lang='less' scoped>
@import '../../style/customerList.less';


.ChooseCustomer {
    .search-input {
        position: relative;
        input {
            background-color: #FFF;
            border-radius: 4px;
            border: 1px solid #E0E1E3;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            height: 50px;
            line-height: 50px;
            outline: 0;
            padding: 0 50px 0 20px;
            transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
            width: 700px;
            font-size: 18px;
        }
    }

    .search-icon {
        width: 100px;
        height: 50px;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        color: #FFFFFF;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
    }


    .tips {
        padding: 0 60px;
        margin: 20px 0;
    }

    .enterprise-list {
        margin-top: 20px;
        padding: 0 60px;
        overflow-y: auto;

        .enterpriseItem {
            width: 400px;
            height: 70px;
            background: #F8F8F8;
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 15px;
            cursor: pointer;

            .customerTypeImg {
                border-radius: 50%;
                width: 70px;
                height: 70px;
                font-size: 70px;
                margin-right: 20px;
            }

            .baseInfo {
                height: 70px;
                width: 310px;

                .customerName {
                    height: 25px;
                    font-size: 24px;
                    color: #333;
                    width: 300px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-top: 5px;
                }

                .customerType {
                    margin-top: 10px;
                    font-size: 16px;
                    color: #666;
                    width: 300px;
                }
            }
        }

        // .list-inner {
        //     height: 500px;
        //     overflow-y: auto;

        //     .enterprise-item {
        //         padding: 20px;
        //     }
        // }
    }

}</style>
